
<template>
    <div class="percentloop">
  
      <li class="percent-list" v-for="(item, index) in percentData" :key="index">
        <div class="wrap">
          <div :class="item.deg > 180 ? 'clip-auto circle' : 'circle'">
            <div class="percent left" :style="`-webkit-transform:rotate(${item.deg}deg);`"></div>
            <div :class="item.deg > 180 ? 'percent right' : 'percent right wth0'" ></div>
          </div>    
          <div class="inside-round">{{ item.deg + 'deg' }}</div>
        </div>
      </li>
  
  
    </div>
  </template>
  
  <script>
    export default {
  
      data() {
        return {
          percentData: [{
              number: '123213',
              deg: 90,
             
            },
            {
              number: '123213',
              deg: 180,
             
            },
            {
              number: '123213',
              deg: 270,
             
            },
            {
              number: '123213',
              deg: 360,
            }
          ]
  
        }
      },
      created() {
      },
      methods: {
  
  
      },
  
  
    }
  
  </script>
  
  <style scoped lang="scss">
    /* 大圆 */
    .wrap {
      background-color: #ccc;
      position: relative;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
     /* 绘制圆环*/
      .circle {
        box-sizing: border-box;
        border: 1px solid #ccc;
        clip: rect(0, 1.5rem, 1.5rem, 0.75rem);   //默认不显示左侧的圆弧  小于180deg的时候
        position: absolute;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        .percent {
          box-sizing: border-box;
          top: -1px;
          left: -1px;
          position: absolute;
          width: 1.5rem;
          height: 1.5rem;
          border-radius: 50%;
        }
  
        .left {
          border: 12px solid #3C8CFF;
          clip: rect(0, 0.75rem, 1.5rem, 0);
        }
        .right {
          border: 12px solid #3C8CFF;
          clip: rect(0, 1.5rem, 1.5rem, 0.75rem);
        }
        //该样式控制左侧的圆弧是否显示
        .wth0 {
          width: 0;
        }
      }
      //该样式控制左侧的圆弧是否显示
      .clip-auto {
        clip: rect(auto, auto, auto, auto);
      }
  
       /* 内圈的小圆*/
      .inside-round {
        position: absolute;
        width: 1.38rem;
        height: 1.38rem;
        background: #fff;
        border-radius: 50%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        text-align: center;
        line-height: 1.38rem;
      }
    }
  
  </style>